//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-736:_7164,_9412,_5172,_5457,_7033,_4808,_2773,_2064;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-736')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-736', "_7164,_9412,_5172,_5457,_7033,_4808,_2773,_2064");
        }
      }catch (ex) {
        console.error(ex);
      }