//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-736:_6664,_3212,_4836,_1988,_4280,_7592,_8744,_9660;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-736')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-736', "_6664,_3212,_4836,_1988,_4280,_7592,_8744,_9660");
        }
      }catch (ex) {
        console.error(ex);
      }